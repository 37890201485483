import { Link } from "react-router-dom";
import CreatePostForm from "../../../components/admin/posts/CreatePostForm";

export default function ChooseWinner() {
  return (
    <section>
      <h1>Definir ganhador</h1>
    </section>
  );
}
